import React, { useEffect } from 'react'
import { navigate, useLocation } from '@reach/router'

// Components & Context
import { usePageProvider } from '../../context/PageProvider'

export default function SearchResults({ data }) {
  const { setDarkFontTopNavValue } = usePageProvider()
  const { search } = useLocation()

  useEffect(() => {
    if (!search) {
      navigate('/')
    }
    setDarkFontTopNavValue(true)
  }, [])

  
  return (
    <div></div>
    )
}
